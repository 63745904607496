import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 25" {...props}>
      <path
        d="M12 0.482422C5.37321 0.482422 0 5.85564 0 12.4824C0 19.1092 5.37321 24.4824 12 24.4824C18.6268 24.4824 24 19.1092 24 12.4824C24 5.85564 18.6268 0.482422 12 0.482422ZM17.767 9.52796C17.775 9.65385 17.775 9.7851 17.775 9.91367C17.775 13.8458 14.7804 18.3753 9.30804 18.3753C7.62054 18.3753 6.05625 17.8851 4.73839 17.0414C4.97946 17.0681 5.20982 17.0788 5.45625 17.0788C6.84911 17.0788 8.12946 16.6074 9.15 15.8092C7.84286 15.7824 6.74464 14.9253 6.36964 13.7467C6.82768 13.8137 7.24018 13.8137 7.71161 13.6931C7.03855 13.5564 6.43359 13.1909 5.99951 12.6586C5.56542 12.1264 5.32898 11.4603 5.33036 10.7735V10.736C5.72411 10.9583 6.1875 11.0949 6.67232 11.1137C6.26476 10.8421 5.93051 10.4741 5.69923 10.0423C5.46794 9.61059 5.34676 9.12846 5.34643 8.63867C5.34643 8.08421 5.49107 7.57796 5.75089 7.13867C6.49795 8.05832 7.43017 8.81048 8.48696 9.34626C9.54375 9.88204 10.7014 10.1894 11.8848 10.2485C11.4643 8.22617 12.975 6.58956 14.7911 6.58956C15.6482 6.58956 16.4196 6.94849 16.9634 7.52706C17.6357 7.40117 18.2786 7.14939 18.8518 6.81189C18.6295 7.50028 18.1634 8.08153 17.5446 8.44849C18.1446 8.38421 18.7232 8.21814 19.2589 7.9851C18.8545 8.57974 18.3482 9.10742 17.767 9.52796Z"
        fill="#E6E6E6"
      />
    </Svg>
  );
};

export default Icon;
