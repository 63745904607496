import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 25" {...props}>
      <path d="M12 0.482422C5.376 0.482422 0 5.85842 0 12.4824C0 19.1064 5.376 24.4824 12 24.4824C18.624 24.4824 24 19.1064 24 12.4824C24 5.85842 18.624 0.482422 12 0.482422ZM17.568 8.64242C17.388 10.5384 16.608 15.1464 16.212 17.2704C16.044 18.1704 15.708 18.4704 15.396 18.5064C14.7 18.5664 14.172 18.0504 13.5 17.6064C12.444 16.9104 11.844 16.4784 10.824 15.8064C9.636 15.0264 10.404 14.5944 11.088 13.8984C11.268 13.7184 14.34 10.9224 14.4 10.6704C14.4083 10.6323 14.4072 10.5926 14.3968 10.555C14.3863 10.5173 14.3668 10.4828 14.34 10.4544C14.268 10.3944 14.172 10.4184 14.088 10.4304C13.98 10.4544 12.3 11.5704 9.024 13.7784C8.544 14.1024 8.112 14.2704 7.728 14.2584C7.296 14.2464 6.48 14.0184 5.868 13.8144C5.112 13.5744 4.524 13.4424 4.572 13.0224C4.596 12.8064 4.896 12.5904 5.46 12.3624C8.964 10.8384 11.292 9.83042 12.456 9.35042C15.792 7.95842 16.476 7.71842 16.932 7.71842C17.028 7.71842 17.256 7.74242 17.4 7.86242C17.52 7.95842 17.556 8.09042 17.568 8.18642C17.556 8.25842 17.58 8.47442 17.568 8.64242Z" fill="#E6E6E6"/>
    </Svg>
  );
};

export default Icon;
